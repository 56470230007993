(function() {
  'use strict';
  window.addEventListener('load', function() {
    var forms = document.querySelectorAll('form');

    Array.prototype.filter.call(forms, function(form) {

      form.addEventListener('change', function(evt) {
        var target = evt.target;
        var value = target.value;

        if ( value && value.replace(/^\s+|\s+$/g, '') ) {
          target.classList.add('has-value');
        } else {
          target.classList.remove('has-value');
        }
      }, false);
    });

    // Маски для полей ввода
    var maskedInputs = document.querySelectorAll('[data-mask]');
    var maskTypeMap = {
      date: [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/],
      dateYear: [/\d/, /\d/, /\d/, /\d/],
      tel: ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/],
      pasportSerials: [/\d/, /\d/, ' ', /\d/, /\d/],
      pasportNumbers: [/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
      pasportCode: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
      snils: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, ' ', /\d/, /\d/],
      inn: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
      ticketSerials: [/\d/, /\d/],
      ticketNumbers: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    };

    Array.prototype.filter.call(maskedInputs, function(it) {
      vanillaTextMask.maskInput({
        inputElement: it,
        mask: maskTypeMap[it.dataset.mask]
      });
    });

    // Выпадающий календарь
    flatpickr('[data-mask="date"]', {
      dateFormat: 'd.m.Y',
      allowInput: true,
      'locale': 'ru',
      onClose: function () {
        this.setDate(this.input.value);
      }
    });
  }, false);
})();

$(document).ready(function() {
  $('[data-toggle="select2"]').select2({
    width: '100%'
  });
  $('[data-toggle="select2-nosearch"]').select2({
    minimumResultsForSearch: Infinity,
    width: '100%'
  });

  $('.needs-validation').each(function() {
    $(this).validate({
      // Validate only visible fields
      ignore: ":hidden",

      // Display error
      invalidHandler: function() {
        swal.fire({
          title: "",
          text: "Заполните все обязательные поля",
          type: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      }
    });
  });

  autosize($('.textarea-autosize'));
});


// Добавляем вожможность выбора строки в таблице
$('.route-table tbody tr').click(function() {
  var $touchspin = $(this).find('.touchspin');

  if ($(this).is('.selected')) {
    $(this).removeClass('selected').removeClass('unfocused');
    $(this).siblings('tr').removeClass('selected').removeClass('unfocused');

    if ($touchspin.length) {
      $touchspin.prop('disabled', true);
      $touchspin.siblings().find('.btn').prop('disabled', true);
    }
  } else {
    $(this).siblings('tr').removeClass('selected').addClass('unfocused');
    $(this).removeClass('unfocused').addClass('selected');

    if ($touchspin.length) {
      $touchspin.prop('disabled', false);
      $touchspin.siblings().find('.btn').prop('disabled', false);
    }
  }
});

// Добавляем вожможность множественного выбора строк в таблице
$('.route-table-multiple tbody tr').click(function() {
  var $touchspin = $(this).find('.touchspin');

  if ($(this).is('.selected')) {
    $(this).removeClass('selected').addClass('unfocused');

    if (!$(this).siblings('tr').hasClass('selected')) {
      $(this).removeClass('unfocused').siblings('tr').removeClass('unfocused');
    }

    if ($touchspin.length) {
      $touchspin.prop('disabled', true);
      $touchspin.siblings().find('.btn').prop('disabled', true);
    }
  } else {
    $(this).removeClass('unfocused').addClass('selected');
    $(this).siblings('tr:not(.selected)').removeClass('selected').addClass('unfocused');

    if ($touchspin.length) {
      $touchspin.prop('disabled', false);
      $touchspin.siblings().find('.btn').prop('disabled', false);
    }
  }
});

$('.route-table .btns-cell').on('click', function(evt) {
  evt.stopPropagation();
});

$('.route-table-multiple .btns-cell').on('click', function(evt) {
  evt.stopPropagation();
});

// Раскрывающийся фильтр
$('#cardsSearchBtn').on('click', function (evt) {
  evt.stopPropagation();
  $('.cards-search-filter').toggleClass('show');

  $(window).click(function (evt) {
    if (!$(evt.target).parents('.cards-search-filter').length && !evt.target.classList.contains('cards-search-filter')) {
      $('.cards-search-filter').removeClass('show');
    }
  });
});
